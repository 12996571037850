/**
 * SPDX-FileCopyrightText: (c) 2023 Liferay, Inc. https://liferay.com
 * SPDX-License-Identifier: LGPL-2.1-or-later OR LicenseRef-Liferay-DXP-EULA-2.0.0-2023-06
 */

function Jethr0Table({children}) {
	return (
		<table className="table table-bordered table-hover table-sm">
			{children}
		</table>
	);
}

export default Jethr0Table;
